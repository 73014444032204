<template>
  <div class="sub-nav">
    <div class="selected-tab-wrapper">
      <p
        class="selected-tab"
        @click="selectTab()"
      >
        {{ selectedTab }}
      </p>
    </div>
    <div :class="[matchMediaQuery ? showNavItem : '', 'px-8']">
      <ul
        :class="subNavClasses"
      >
        <li
          v-for="item in navItems"
          :key="item.name"
          :class="(!matchMediaQuery ? 'd-flex text-center' : 'nav-item text-center' + ((item.route && isCurrentRoute(item.route)) ? ' active' : '')) + ((item.disabled) ? ' disabled' : '')"
        >
          <a-link
            :to="item.route"
            :disabled="item.disabled"
            :datatest="item.datatest"
            :class="!matchMediaQuery ? ('nav-link px-1 gap-x-2 flex items-center' + ((item.route && isCurrentRoute(item.route)) ? ' active' : '')) : 'responsive-subnav'"
          >
            <component
              :is="item.icon"
              v-if="item.icon"
              w="24"
              h="24"
              title=""
            />
            <span>{{ item.name }}</span>
          </a-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { includes, keys, filter, findLast, find } from 'lodash-es'
import {
  CampaignsIcon,
  ClientsIcon,
  AgenciesIcon,
  DashboardActivityIcon,
  ContactsIcon,
  RequestForProposalIcon,
  NavIcons
} from '@/components/icons'
import RecoV2Mixin from '@/mixins/RecoV2.mixin'
import { mapActions } from 'vuex'
const { DashboardIcon } = NavIcons

const SUB_NAV_TYPES = [
  'client',
  'product-admin',
  'user-admin',
  'styles-admin',
  'exports-admin',
  'submission-admin',
  'status-admin',
  'audience-admin',
  'presentation-admin',
  'product-config',
  'db-migrations',
  'company-admin',
  'campaigns-admin',
  'group-admin',
  'reporting-admin',
  'company',
  'group',
  'recommendation-admin',
  'custom-fields',
  'clients',
  'workspace',
  'dataset-group',
  'AdminNotifications',
  'AdminNotificationConfiguration',
  'admin-task-queue',
  'admin-backend-config'
]

export default {
  components: {
    AgenciesIcon,
    CampaignsIcon,
    ClientsIcon,
    ContactsIcon,
    DashboardActivityIcon,
    DashboardIcon,
    RequestForProposalIcon
  },
  mixins: [RecoV2Mixin],
  props: {
    nav: {
      type: String,
      required: true,
      validator (value) {
        return includes(SUB_NAV_TYPES, value)
      }
    }
  },

  data () {
    return {
      matchMedia: false,
      showNavItem: ['hide-nav-item'],
      query: '',
      clickedTab: false
    }
  },

  computed: {
    subNavClasses () {
      const classes = []
      if (this.nav === 'clients' || this.nav === 'workspace') {
        classes.push('flex')
        classes.push('justify-start')
        classes.push('gap-x-2')
      } else {
        classes.push('flex')
        classes.push('justify-around')
      }
      if (this.matchedMediaQuery) {
        classes.push('responsive-block')
      }
      return classes
    },
    client: {
      get () {
        return this.$store.state.Client.client
      }
    },

    curPage: {
      get () {
        return this.$route.name
      }
    },

    matchMediaQuery: {
      get () {
        return this.matchMedia
      }
    },

    canViewClientInsights: {
      get () {
        return this.$permission.hasPermission('ClientInsights') &&
          this.client &&
          this.client.companyGroup &&
          this.client.companyGroup.company &&
          !this.client.companyGroup.company.disableGeo
      }
    },

    canViewClientReviews: {
      get () {
        return this.$permission.hasPermission('ClientReviews')
      }
    },

    canViewClientSocial: {
      get () {
        return this.$permission.hasPermission('ClientSocial')
      }
    },

    canViewClientCompetitiors: {
      get () {
        return this.$permission.hasPermission('ClientCompetitiors')
      }
    },

    canViewContacts () {
      return this.$permission.hasPermission('ContactViewAll') ||
        this.$permission.hasPermission('ContactViewForOwnCompany') ||
        this.$permission.hasPermission('ContactViewForOwnGroup') ||
        this.$permission.hasPermission('ContactViewOwn')
    },

    canViewNewHomepage () {
      return this.$permission.hasPermission('NewHomePage')
    },

    canViewRequestForProposals () {
      return this.$permission.hasPermission('RequestForProposalViewAll') ||
        this.$permission.hasPermission('RequestForProposalViewForOwnCompany') ||
        this.$permission.hasPermission('RequestForProposalViewForOwnGroup') ||
        this.$permission.hasPermission('RequestForProposalViewOwn')
    },

    allNavItems: {
      get () {
        if (this.nav === 'client') {
          return [
            {
              name: 'Campaigns',
              datatest: 'campaigns-menu',
              route: {
                name: 'ClientMyCampaigns',
                props: {
                  id: this.client.id
                }
              }
            },
            {
              name: 'Client Info',
              route: {
                name: 'ClientInfo',
                props: {
                  id: this.client.id
                }
              }
            },
            {
              name: 'Insights',
              hidden: !this.canViewClientInsights,
              route: {
                name: 'ClientInsights',
                props: {
                  id: this.client.id
                }
              }
            },
            {
              name: 'Billing Details',
              route: {
                name: 'ClientBillingDetails',
                props: {
                  id: this.client.id
                }
              }
            },
            {
              name: 'Competitiors',
              disabled: true,
              hidden: !this.canViewClientCompetitiors
            },
            {
              name: 'Reviews',
              disabled: true,
              hidden: !this.canViewClientReviews
            }
          ]
        } else if (this.nav === 'clients') {
          return [
            {
              name: 'Clients',
              icon: 'ClientsIcon',
              datatest: 'clients-workspace',
              route: {
                name: 'WorkspaceClientList'
              },
              disabled: false
            },
            {
              name: 'Campaigns',
              icon: 'CampaignsIcon',
              route: {
                name: 'WorkspaceClientList'
              },
              disabled: true
            }
          ]
        } else if (this.nav === 'workspace') {
          return [
            {
              name: 'Home',
              icon: 'DashboardIcon',
              route: {
                name: 'WorkspaceHomepage'
              },
              hidden: !this.canViewNewHomepage,
            },
            {
              name: 'Activity',
              icon: 'DashboardActivityIcon',
              route: {
                name: 'WorkspaceDashboard'
              },
              disabled: !this?.showActivityTab,
              hidden: !this?.showActivityTab
            },
            {
              name: 'Clients',
              icon: 'ClientsIcon',
              route: {
                name: 'WorkspaceClientList',
                subNav: 'WorkspaceClients'
              },
              disabled: false
            },
            {
              name: 'Campaigns',
              icon: 'CampaignsIcon',
              route: {
                name: 'WorkspaceCampaignList',
                subNav: 'WorkspaceCampaigns'
              },
              disabled: false
            },
            {
              name: 'Contacts',
              icon: 'ContactsIcon',
              route: {
                name: 'WorkspaceContactList',
                subNav: 'WorkspaceContacts'
              },
              hidden: !this.canViewContacts,
              disabled: false
            },
            {
              name: 'RFPs',
              icon: 'RequestForProposalIcon',
              route: {
                name: 'WorkspaceRequestForProposalList',
                subNav: 'WorkspaceRequestForProposals'
              },
              hidden: !this.canViewRequestForProposals || !this.currentUserCompany.enableRequestForProposal,
              disabled: false
            },
            {
              name: 'Agencies',
              icon: 'AgenciesIcon',
              route: {
                name: 'WorkspaceAllAgencies',
                subNav: 'WorkspaceAgencies'
              },
              disabled: false,
              hidden: !this.$permission.hasPermission('AgencyAccessTab') || !this.showAgencyTab
            }
          ]
        } else if (this.nav === 'product-admin') {
          return [
            {
              name: 'Products',
              route: {
                name: 'AdminProduct'
              },
              disabled: false
            },
            {
              name: 'Product Attributes',
              route: {
                name: 'AdminProductOptions'
              },
              disabled: !this.$permission.hasRoute('AdminProduct')
            },
            {
              name: 'External Mappings',
              route: {
                name: 'AdminProductExternalMappings'
              },
              disabled: !this.$permission.hasRoute('AdminProductExternalMappings')
            },
            {
              name: 'Recommendation Settings',
              route: {
                name: 'AdminProductRecommendation'
              },
              disabled: !this.$permission.hasRoute('AdminProductRecommendation') || !this.currentUserCompany.useRecommendationAlgorithm
            },
            {
              name: 'Recommendation Data',
              route: {
                name: 'AdminDataset'
              },
              disabled: !this.$permission.hasRoute('AdminDataset') || !this.currentUserCompany.useRecommendationAlgorithm
            }
          ]
        } else if (this.nav === 'user-admin') {
          return [
            {
              name: 'Users',
              route: {
                name: 'AdminUser'
              }
            },
            {
              name: 'User Roles',
              route: {
                name: 'AdminUserRoles'
              }
            }
          ]
        } else if (this.nav === 'styles-admin') {
          return [
            {
              name: 'General',
              route: {
                name: 'AdminStyles',
                params: {
                  entity: 'general'
                }
              },
              disabled: !this.$permission.hasRoute('AdminStyles')
            },
            {
              name: 'Navigation',
              route: {
                name: 'AdminStyles',
                params: {
                  entity: 'navigation'
                }
              },
              disabled: !this.$permission.hasRoute('AdminStyles')
            },
            {
              name: 'Buttons',
              route: {
                name: 'AdminStyles',
                params: {
                  entity: 'buttons'
                }
              },
              disabled: !this.$permission.hasRoute('AdminStyles')
            },
            {
              name: 'Text',
              route: {
                name: 'AdminStyles',
                params: {
                  entity: 'text'
                }
              },
              disabled: !this.$permission.hasRoute('AdminStyles')
            },
            {
              name: 'Components',
              route: {
                name: 'AdminStyles',
                params: {
                  entity: 'components'
                }
              },
              disabled: !this.$permission.hasRoute('AdminStyles')
            },
            {
              name: 'Charts',
              route: {
                name: 'AdminStyles',
                params: {
                  entity: 'charts'
                }
              },
              disabled: !this.$permission.hasRoute('AdminStyles')
            }
          ]
        } else if (this.nav === 'exports-admin') {
          return [
            {
              name: 'Insertion Order',
              route: {
                name: 'AdminInsertionOrder'
              }
            },
            {
              name: 'Campaign Export',
              route: {
                name: 'AdminCampaignExport'
              }
            },
            {
              name: 'Vendor Export',
              route: {
                name: 'AdminVendorExport'
              }
            },
            {
              name: 'Styling',
              route: {
                name: 'AdminExportStyling'
              }
            }
          ]
        } else if (this.nav === 'submission-admin') {
          return [
            {
              name: 'Submissions',
              route: {
                name: 'AdminSubmissionList'
              },
              disabled: !this.$permission.hasRoute('AdminSubmissionList')
            },
            {
              name: 'Forms',
              route: {
                name: 'AdminFormList'
              },
              disabled: !this.$permission.hasRoute('AdminFormList')
            },
            {
              name: 'Fulfillment',
              route: {
                name: 'AdminFulfillmentModuleList'
              },
              disabled: false
            },
            {
              name: 'Vendors',
              route: {
                name: 'AdminVendorList'
              },
              disabled: !this.$permission.hasRoute('AdminVendorList')
            },
            {
              name: 'API Logs',
              route: {
                name: 'AdminAPILogs'
              },
              disabled: !this.$permission.hasRoute('AdminAPILogs')
            }
          ]
        } else if (this.nav === 'audience-admin') {
          return [
            {
              name: 'Presets',
              route: {
                name: 'AdminAudiencePreset'
              },
              disabled: !this.$permission.hasRoute('AdminAudience')

            },
            {
              name: 'Configuration',
              route: {
                name: 'AdminAudienceConfiguration'
              },
              disabled: !this.$permission.hasRoute('AdminAudience')
            }
          ]
        } else if (this.nav === 'status-admin') {
          return [
            {
              name: 'Statuses',
              route: {
                name: 'AdminStatus'
              },
              disabled: !this.$permission.hasRoute('AdminStatus')

            },
            {
              name: 'Statuses Display',
              route: {
                name: 'StatusesDisplay'
              },
              disabled: !this.$permission.hasRoute('AdminStatus')
            }
          ]
        } else if (this.nav === 'presentation-admin') {
          return [
            {
              name: 'Templates',
              route: {
                name: 'AdminPresentationTemplates'
              },
              disabled: !this.$permission.hasRoute('AdminPresentationTemplates')
            },
            {
              name: 'Slides',
              route: {
                name: 'AdminPresentationSlides'
              },
              disabled: !this.$permission.hasRoute('AdminPresentationSlides')
            },
            {
              name: 'Slide Layouts',
              route: {
                name: 'AdminPresentationLayouts'
              },
              disabled: !this.$permission.hasRoute('AdminPresentationLayouts')
            }
          ]
        } else if (this.nav === 'product-config') {
          return [
            {
              name: 'Product Details',
              route: {
                name: this.headerText === 'New Product' ? 'AdminNewProduct' : 'AdminProductDetails'
              },
              disabled: !this.$permission.hasRoute('AdminEditProduct')
            },
            {
              name: 'Configuration',
              route: {
                name: 'AdminTacticsAndOptions'
              },
              disabled: this.headerText === 'New Product' || !this.$permission.hasRoute('AdminEditProduct')
            },
            {
              name: 'Slides & Collateral',
              route: {
                name: 'AdminCollateralAndDocuments'
              },
              disabled: this.headerText === 'New Product' || !this.$permission.hasRoute('AdminEditProduct')
            },
            {
              name: 'Submissions',
              route: {
                name: 'AdminSubmissions'
              },
              disabled: this.headerText === 'New Product' || !this.$permission.hasRoute('AdminEditProduct')
            },
            {
              name: 'Recommendation Settings',
              route: {
                name: 'AdminRecommendationSettings'
              },
              disabled: this.headerText === 'New Product' || !this.$permission.hasRoute('AdminEditProduct') || !this.currentUserCompany.useRecommendationAlgorithm
            },
            {
              name: 'Reco V2 Settings',
              route: {
                name: 'AdminRecoV2Settings'
              },
              disabled: this.headerText === 'New Product' || !this.isRecoV2Enabled(this.currentUserCompany)
            },
            {
              name: 'External Mappings',
              route: {
                name: 'AdminExternalMappings'
              },
              disabled: this.headerText === 'New Product' || !this.$permission.hasRoute('AdminEditProduct') || !this.$store.state.enableExternalMappings
            }]
        } else if (this.nav === 'company-admin') {
          const nav = [
            {
              name: 'Groups',
              route: {
                name: 'GroupList'
              }
            }
          ]

          if (this.$permission.hasRoute('CompanyList')) {
            nav.unshift({
              name: 'Companies',
              route: {
                name: 'CompanyList'
              }
            })
          }

          if (this.$permission.hasPermission('AdminViewCompanyActivity')) {
            nav.push({
              name: 'Activity',
              route: {
                name: 'Activity'
              }
            })
          }
          return nav
        } else if (this.nav === 'db-migrations') {
          return [
            {
              name: 'Default',
              route: {
                name: 'AdminDBMigrations',
                params: { database: 'default' }
              }
            },
            {
              name: 'Geo',
              route: {
                name: 'AdminDBMigrations',
                params: { database: 'geo' }
              }
            },
            {
              name: 'Reporting',
              route: {
                name: 'AdminDBMigrations',
                params: { database: 'reporting' }
              }
            },
            {
              name: 'Logging',
              route: {
                name: 'AdminDBMigrations',
                params: { database: 'logging' }
              }
            }
          ]
        } else if (this.nav === 'reporting-admin') {
          return [
            {
              name: 'Groups',
              route: {
                name: 'AdminReportingGroups'
              }
            },
            {
              name: 'Dashboards',
              route: {
                name: 'AdminReportingDashboards'
              }
            }
          ]
        } else if (this.nav === 'company') {
          return [
            {
              name: 'Company Settings',
              route: {
                name: this.headerText === 'New Company' ? 'AdminNewCompany' : 'AdminEditCompanySettings'
              },
              disabled: false
            },
            {
              name: 'Workflow Settings',
              datatest: 'company-workflow-settings',
              route: {
                name: 'AdminEditCompanyWorkflowSettings'
              },
              disabled: this.headerText === 'New Company'
            }
          ]
        } else if (this.nav === 'group') {
          return [
            {
              name: 'Group Settings',
              route: {
                name: this.headerText === 'New Group' ? 'AdminNewGroup' : 'AdminEditGroupSettings'
              },
              disabled: false
            },
            {
              name: 'Workflow Settings',
              datatest: 'group-workflow-settings',
              route: {
                name: 'AdminEditGroupWorkflowSettings'
              },
              disabled: this.headerText === 'New Group'
            }
          ]
        } else if (this.nav === 'recommendation-admin') {
          return [
            {
              name: 'Strategies',
              route: {
                name: 'AdminStrategies'
              },
              disabled: false
            },
            {
              name: 'Rules',
              route: {
                name: 'AdminRules'
              },
              disabled: false
            },

            {
              name: 'Factors',
              route: {
                name: 'AdminFactors'
              },
              disabled: false
            },
            {
              name: 'Dataset Admin',
              route: {
                name: 'AdminDatasetAdmin'
              },
              disabled: false
            },
            {
              name: 'Product Tiers',
              route: {
                name: 'AdminProductTiers'
              },
              disabled: false
            }
          ]
        } else if (this.nav === 'custom-fields') {
          return [
            {
              name: 'Fields',
              route: {
                name: 'AdminCustomFields'
              },
              disabled: false
            },
            {
              name: 'Categories',
              route: {
                name: 'AdminCustomFieldsCategories'
              },
              disabled: false
            },
            {
              name: 'UI Configuration',
              route: {
                name: 'AdminCustomFieldsUIConfiguration'
              },
              disabled: false
            }
          ]
        } else if (this.nav === 'dataset-group') {
          return [
            {
              name: 'Datasets',
              route: {
                name: 'AdminDatasetList'
              },
              disabled: false
            },
            {
              name: 'Dataset Products',
              route: {
                name: 'AdminDatasetProducts'
              },
              disabled: false
            },
            {
              name: 'Dataset Goals',
              route: {
                name: 'AdminDatasetGoals'
              },
              disabled: false
            },
            {
              name: 'Dataset Client Categories',
              route: {
                name: 'AdminDatasetCategories'
              },
              disabled: false
            }
          ]
        } else if (this.nav === 'campaigns-admin') {
          return [
            {
              name: 'Type',
              route: {
                name: 'CampaignType'
              },
              disabled: false
            },
            {
              name: 'UI Configuration',
              route: {
                name: 'CampaignUIConfiguration'
              },
              disabled: false
            },
            {
              name: 'Deal Configuration',
              route: {
                name: 'CampaignDealConfiguration'
              },
              disabled: false
            }
          ]
        } else if (this.nav === 'AdminNotifications') {
          const navs = []
          navs.push({ name: 'Email', route: { name: 'AdminNotificationsEmail' } })
          navs.push({ name: 'Slack', route: { name: 'AdminNotificationsSlack' } })
          return navs
        } else if (this.nav === 'AdminNotificationConfiguration') {
          const navs = []
          navs.push({ name: 'Notification Types', route: { name: 'AdminNotificationConfigurationUserNotificationType' } })
          navs.push({ name: 'Email Templates', route: { name: 'AdminNotificationConfigurationEmail' } })
          navs.push({ name: 'Notification Templates', route: { name: 'AdminNotificationConfigurationUserNotificationTemplate' } })
          return navs
        } else if (this.nav === 'admin-task-queue') {
          return [
            {
              name: 'Active Queue',
              route: {
                name: 'AdminTaskQueueActive'
              },
              disabled: false
            },
            {
              name: 'History',
              route: {
                name: 'AdminTaskQueueHistory'
              },
              disabled: false
            }
          ]
        } else if (this.nav === 'admin-backend-config') {
          return [
            {
              name: 'Full Config',
              route: {
                name: 'AdminBackendConfigFull'
              },
              disabled: false
            },
            {
              name: 'Live Config',
              route: {
                name: 'AdminBackendConfigLiveConfig',
                subNav: 'AdminBackendConfigLive'
              },
              disabled: false
            }
          ]
        }
        return []
      }
    },

    navItems: {
      get () {
        return filter(this.allNavItems, item => !item.hidden)
      }
    },

    selectedTab: {
      get () {
        if (this.nav === 'client') {
          return this.$route.name.split('Client')[1] === 'Info'
            ? 'Client Info'
            : this.$route.name.split('Client')[1]
        } else if (this.nav === 'product') {
          return 'Products'
        } else if (this.nav === 'styles-admin') {
          return 'Navigation'
        } else if (this.nav === 'audience-admin') {
          return 'AdminAudiencePreset'
        } else if (this.nav === 'company') {
          return 'CompanySettings'
        }
        return ''
      }
    },

    headerText: {
      get () {
        const matchedRouteHeaderText = findLast(this.$route.matched, route => {
          return route.meta.headerText
        })

        if (matchedRouteHeaderText) return matchedRouteHeaderText.meta.headerText
        return undefined
      }
    },

    currentUserCompany: {
      get () {
        return this.$store.state.User.user.companyGroup.company
      }
    },
    campaignFields: {
      get () {
        return this.$store.state.Campaign.campaignFields
      }
    },

    showAgencyTab: {
      get () {
        return find(this.campaignFields.fields, f => f.name === 'Agency')?.isVisible || false
      }
    },

    showActivityTab: {
      get () {
        return this.$store.state.Dashboard.companyDashboardSettings.showActivityTab
      }
    }

  },

  mounted () {
    this.query = window.matchMedia('(max-width: 608px)')
    this.checkMedia(this.query)
    this.query.addListener(this.checkMedia)
    window.addEventListener('click', this.handleAllClicks)
  },
  async created () {
    await this.loadCampaignFields()
  },

  destroyed () {
    this.query.removeListener(this.checkMedia)
    window.removeEventListener('click', this.handleAllClicks)
  },

  methods: {
    async loadCampaignFields () {
      const params = {
        companyId: parseInt(this.currentUserCompany?.id)
      }
      await this.listAllCampaignFields(params)
    },
    checkMedia (listener) {
      if (listener.matches) {
        this.matchMedia = true
      } else {
        this.matchMedia = false
      }
    },

    selectTab () {
      this.clickedTab = true
    },

    handleAllClicks (e) {
      if (this.showNavItem.length > 0 && this.clickedTab) {
        this.showNavItem = []
      } else {
        this.showNavItem.push('hide-nav-item')
        this.clickedTab = false
      }
    },

    isCurrentRoute (routeToCheck) {
      if (this.$route.meta.name === routeToCheck.subNav && routeToCheck.subNav !== undefined) {
        return true
      } else if (this.$route.name !== routeToCheck.name && this.$route.meta.name !== routeToCheck.name) {
        return false
      }
      if (routeToCheck.params && this.$route.params) {
        // Params match
        const paramsMatch = keys(routeToCheck.params).map(key => {
          return routeToCheck.params[key] === this.$route.params[key]
        })

        return !(paramsMatch.indexOf(false) >= 0)
      }
      return true
    },

    ...mapActions([
      'listAllCampaignFields'
    ])
  }
}
</script>

<style scoped>
.sub-nav {
  position: fixed;
  top: var(--app-header-height);
  left: var(--app-nav-width);
  right: 0;
  height: var(--app-sub-nav-height);
  background-color: var(--sub-nav-background-color);
  border-bottom: 1px solid var(--sub-nav-border-color);
  z-index: 9;
}

.sub-nav ul {
  height: var(--app-sub-nav-height);
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.nav-link {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.nav-link:hover {
  border-bottom: 4px solid var(--sub-nav-hover-border-color);
}

.nav-link.active {
  color: var(--sub-nav-active-text-color);
  border-bottom: 4px solid var(--sub-nav-active-border-color);
  font-weight: 500;
}

.nav-link >>> svg {
  fill: var(--primary-600);
}

.nav-link.active >>> svg {
  fill: var(--sub-nav-active-text-color);
  color: var(--sub-nav-active-text-color);
}

.selected-tab-wrapper{
  display: none;
  padding: 0px 20px;
  height: var(--app-sub-nav-height);
}

.responsive-subnav {
  display: block;
  font-weight: 400;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  line-height: 42px;
  box-sizing: border-box;
  color: white;
  z-index: 99999999998;
  -webkit-font-smoothing: antialiased;
  color: var(--sub-nav-item-responsive-text-color)
}

.selected-tab {
  padding: 15px 5px;
  font-size: 18px;
  cursor: pointer;
  color: var(--link-color);
  margin-left: 30px;
}

.responsive-block {
  background-color: var(--sub-nav-responsive-background-color);
  position: relative;
  top: -50px;
}

.nav-item {
  font-size: 16px;
}

.nav-item:hover:not(.disabled),
.nav-item.active {
  background-color: var(--app-nav-active-color);
}

.hide-nav-item {
  display: none
}

@media(max-width: 768px) {
  .sub-nav {
    left: 0;
  }
}

@media(max-width: 608px) {
  .selected-tab-wrapper{
    display: flex
  }

  .sub-nav ul {
    height: auto
  }

  .nav-item ul li:active {
    font-weight: 400;
    border-bottom: 4px solid transparent;
    background: var(--sub-nav-item-responsive-background);
  }
}
</style>
