<template>
  <div
    class="sparkle-container"
    :class="{ animate: animate }"
    :style="containerStyle"
  >
    <svg
      viewBox="0 0 25 25"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="filled-star"
    >
      <path
        d="M12.5 3.53125C12.9098 3.53125 13.2781 3.78128 13.4294 4.16214L15.4021 9.1292L20.3691 11.1019C20.75 11.2531 21 11.6214 21 12.0312C21 12.4411 20.75 12.8094 20.3691 12.9606L15.4021 14.9333L13.4294 19.9004C13.2781 20.2812 12.9098 20.5312 12.5 20.5312C12.0902 20.5312 11.7219 20.2812 11.5706 19.9004L9.59795 14.9333L4.63089 12.9606C4.25003 12.8094 4 12.4411 4 12.0312C4 11.6214 4.25003 11.2531 4.63089 11.1019L9.59795 9.1292L11.5706 4.16214C11.7219 3.78128 12.0902 3.53125 12.5 3.53125Z"
        fill="url(#paint0_linear_3445_5674)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3445_5674"
          x1="12.5"
          y1="3.53125"
          x2="12.5"
          y2="20.5312"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.2145"
            stop-color="#2E76BC"
          />
          <stop
            offset="0.7405"
            stop-color="#6D57A5"
          />
        </linearGradient>
      </defs>
    </svg>

    <svg
      viewBox="0 0 24 25"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="outline-star"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 4.45676C12.4098 4.45676 12.7781 4.70679 12.9294 5.08765L14.9021 10.0547L19.8691 12.0274C20.25 12.1786 20.5 12.547 20.5 12.9568C20.5 13.3666 20.25 13.7349 19.8691 13.8861L14.9021 15.8588L12.9294 20.8259C12.7781 21.2067 12.4098 21.4568 12 21.4568C11.5902 21.4568 11.2219 21.2067 11.0706 20.8259L9.09795 15.8588L4.13089 13.8861C3.75003 13.7349 3.5 13.3666 3.5 12.9568C3.5 12.547 3.75003 12.1786 4.13089 12.0274L9.09795 10.0547L11.0706 5.08765C11.2219 4.70679 11.5902 4.45676 12 4.45676ZM12 8.16601L10.7975 11.1939C10.6958 11.4499 10.4931 11.6526 10.2372 11.7542L7.20925 12.9568L10.2372 14.1593C10.4931 14.2609 10.6958 14.4636 10.7975 14.7196L12 17.7475L13.2025 14.7196C13.3042 14.4636 13.5069 14.2609 13.7628 14.1593L16.7907 12.9568L13.7628 11.7542C13.5069 11.6526 13.3042 11.4499 13.2025 11.1939L12 8.16601Z"
        fill="url(#paint0_linear_3445_5668)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3445_5668"
          x1="12"
          y1="4.45676"
          x2="12"
          y2="21.4568"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.2145"
            stop-color="#2E76BC"
          />
          <stop
            offset="0.7405"
            stop-color="#6D57A5"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24
    },

    animate: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    containerStyle () {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      }
    }
  }
}
</script>

<style scoped>
.sparkle-container {
  position: relative;
  --full-scale: scale(1);
  --small-scale: scale(0.4);
  --transition-time: 2s;
}

.filled-star {
  position: absolute;
  top: 0;
  left: 0;
  transform: var(--small-scale);
  transform-origin: top left;
}

.outline-star {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: var(--full-scale);
  transform-origin: bottom right;
}

@keyframes filledStarScale {
  0% {
    transform: var(--small-scale);
  }
  25% {
    transform: var(--full-scale);
  }
  50% {
    transform: var(--full-scale);
  }
  75% {
    transform: var(--small-scale);
  }
  1000% {
    transform: var(--small-scale);
  }
}

.sparkle-container.animate .filled-star {
  animation: filledStarScale var(--transition-time) infinite ease;
}

@keyframes outlineStarScale {
  0% {
    transform: var(--full-scale);
  }
  25% {
    transform: var(--small-scale);
  }
  50% {
    transform: var(--small-scale);
  }
  75% {
    transform: var(--full-scale);
  }
  100% {
    transform: var(--full-scale);
  }
}

.sparkle-container.animate .outline-star {
  animation: outlineStarScale var(--transition-time) infinite ease;
}
</style>
