<template>
  <overlay-pane
    class="overlay-pane-main d-flex flex-row"
    :show="show"
    width="25%"
    @close="close"
  >
    <page-spinner v-if="!isLoaded" />
    <div
      v-else
      ref="notificationPanel"
      class="d-flex flex-column notifications-pane header-tab"
      @touchmove.prevent
    >
      <div class="header-slot">
        <div class="d-flex flex-row align-items-center header-row">
          <div class="header-text">
            Notifications
          </div>
        </div>
        <div class="wrapper-header" />
      </div>
      <div
        ref="scrollable"
        class="main-slot main-tab"
        @scroll="enforceScroll"
      >
        <div
          v-for="notification in userNotifications"
          :id="`user_notification_${notification.id}`"
          :key="notification.id"
          class="notification"
        >
          <comment-notification
            v-if="notification.notificationType==='comment'"
            :notification="notification"
            :show-groups="showGroups"
            @close="close"
          />
          <global-notification
            v-if="notification.notificationType==='global'"
            :notification="notification"
          />
          <task-notification
            v-if="notification.notificationType==='task_assign' || notification.notificationType==='task_remind'"
            :notification="notification"
            @user-task="goToUserTask"
          />
          <task-completed-notification
            v-if="notification.notificationType==='task_completed'"
            :notification="notification"
            @user-task="goToUserTask"
          />
          <e-signature-notification
            v-if="notification.notificationType==='document_invitation_declined' || notification.notificationType==='document_invitation_signed' || notification.notificationType==='document_complete'"
            :notification="notification"
            @close="close"
          />
          <request-for-proposal-assign-notification
            v-if="notification.notifiableType === 'RequestForProposal' && notification.notificationType === 'assign'"
            :notification="notification"
            @close="close"
          />
          <div class="wrapper-header" />
        </div>
        <div v-if="showBottomMessage">
          <div class="bottom-message">
            You have reached the bottom of the feed
          </div>
          <div class="d-flex flex-column align-items-center scroll-top">
            <a @click="scrollToTop">Scroll to the top</a>
          </div>
        </div>
      </div>
      <div class="footer-slot">
        <div class="wrapper-close" />
        <a-button
          variant="primary"
          class="close"
          @click="close"
        >
          Close
        </a-button>
      </div>
    </div>
  </overlay-pane>
</template>
<script>
import { isNumber } from 'lodash-es'
import { mapActions } from 'vuex'
import CommentNotification from './CommentNotification.vue'
import GlobalNotification from './GlobalNotification.vue'
import TaskNotification from './TaskNotification.vue'
import TaskCompletedNotification from './TaskCompletedNotification.vue'
import ESignatureNotification from './ESignatureNotification.vue'
import RequestForProposalAssignNotification from './RequestForProposalAssignNotification.vue'

export default {
  components: {
    CommentNotification,
    GlobalNotification,
    TaskNotification,
    TaskCompletedNotification,
    ESignatureNotification,
    RequestForProposalAssignNotification
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    userId: {
      required: true,
      validator (value) {
        return value === null || isNumber(value)
      }
    }
  },

  data () {
    return {
      isLoaded: false,
      userNotifications: [],
      showBottomMessage: false
    }
  },

  computed: {
    showGroups () {
      if (this.userNotifications.length === 0) return false
      return Array.from(new Set(this.userNotifications.map(x => x.groupName))).length > 1
    }
  },

  async created () {
    await this.loadUserNotifications()
    window.addEventListener('mousedown', this.onMouseDown)
  },

  beforeDestroy () {
    window.removeEventListener('mousedown', this.onMouseDown)
  },

  methods: {
    onMouseDown (e) {
      if (this.$refs.notificationPanel && !(e.target === this.$refs.notificationPanel || this.$refs.notificationPanel.contains(e.target))) {
        this.close()
      }
    },

    async loadUserNotifications () {
      if (this.userId !== null) {
        try {
          this.userNotifications = await this.getUserNotificationsList()
          this.checkUnreadTaskNotifications()
          this.isLoaded = true
        } catch (err) {
        }
      } else this.isLoaded = false
    },

    checkUnreadTaskNotifications () {
      const isTaskNotification = this.userNotifications.filter(notification => notification.isUnread && notification.notificationType === 'task_assign')?.length > 0
      if (isTaskNotification) this.$emit('task-notification')
    },

    enforceScroll (event) {
      this.showBottomMessage = true
      const element = event.currentTarget
      const availableScroll = element.scrollHeight - element.offsetHeight
      const scrolled = element.scrollTop
      if (availableScroll === 0) {
        return
      }

      event.stopPropagation()
      if (scrolled === 0) {
        element.scrollTop = 1
      } else if (scrolled === availableScroll) {
        element.scrollTop = scrolled - 1
      }
    },

    scrollToTop () {
      this.$refs.scrollable.scrollTop = 0
    },

    async scrollToElement (notificationId) {
      const elementId = 'user_notification_' + notificationId
      const el = document.getElementById(elementId)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },

    goToUserTask (obj) {
      this.$emit('user-task', obj.taskId)
    },

    close () {
      this.userNotifications.forEach(element => { element.isUnread = false })
      this.$emit('close')
    },

    ...mapActions(['getUserNotificationsList'])
  }
}
</script>
<style scoped>
.overlay-pane-main :deep(.close-button) {
  top: 10px;
  right: 15px;
}
.overlay-pane-main :deep(.overlay-pane) {
    top: 60px;
    padding: 20px 0;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.40);
}
.overlay-pane-main :deep(.hidden-content-overlay) {
    top:60px;
}

.header-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: none;
}
.notifications-pane {
  padding: 0px 0px 0px 0px;
}
.header-row {
  margin-bottom: 15px;
}
.main-slot {
  padding: 0px 6px;
}
.main-tab {
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.footer-slot {
  padding: 10px 6px 0px 0px;
  display:table-cell;
  text-align: center;
  margin-bottom: 50px;
}
.wrapper-close{
  border-bottom: 1px solid #ccc;
  margin-left: -20px;
  margin-right: -25px;
}
.wrapper-header{
  border-bottom: 1px solid #ccc;
}
.close {
  margin-top: 10px;
}
.header-text {
  font-size: 20px;
  font-weight: 500;
}
.bottom-message{
  margin-top: 20%;
  display:flex;
  justify-content: center;
  color: rgb(97, 91, 91);
  font-style: italic;
}
.scroll-top{
  margin-top: 2%;
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
}
.header-slot {
  padding: 10px 26px;
}

.notification {
  margin: 0 20px;
}
</style>
