import Vue from 'vue'

function create (params = {}, data) {
  const { requestForProposalId } = params
  return Vue.api
    .post(`/request-for-proposals/${requestForProposalId}/files`, { data })
}

function _delete (params = {}) {
  const { requestForProposalId, ids } = params
  return Vue.api.delete(`/request-for-proposals/${requestForProposalId}/files`, { data: { ids } })
}

function list (params = {}) {
  const { requestForProposalId } = params
  return Vue.api.get(`/request-for-proposals/${requestForProposalId}/files`)
}

function restore (params = {}) {
  const { requestForProposalId, ids } = params
  return Vue.api.patch(`/request-for-proposals/${requestForProposalId}/files`, { data: { ids } })
}

function update (params = {}) {
  const { requestForProposalId, id, fileName } = params
  return Vue.api.patch(`/request-for-proposals/${requestForProposalId}/files/${id}`, { data: { fileName } })
}

export default {
  create,
  delete: _delete,
  list,
  restore,
  update
}
