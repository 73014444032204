<template>
  <div
    :class="classes"
  >
    <div
      v-if="label !== '' || rightLabel !== ''"
      class="d-flex flex-row"
    >
      <a-label
        v-if="label !== ''"
        :for="inputId"
        :variant="variant"
        :class="warningMessage ? 'label-with-warning' : required ? ' required-label': ''"
      >
        {{ label }}
        <icon-link
          v-if="warningMessage"
          class="warning-icon"
          icon="Warning"
          w="12"
          h="12"
          :tooltip="warningMessage"
        />
      </a-label>

      <a-label
        v-if="rightLabel !== ''"
        :for="inputId"
        class="align-right"
      >
        {{ rightLabel }}
      </a-label>
    </div>

    <input
      :id="inputId"
      ref="inputElement"
      v-model="localValue"
      :data-test="datatest"
      :type="inputType"
      :step="inputStep"
      :placeholder="placeholder"
      :disabled="disabled"
      :aria-invalid="invalid"
      :max="max"
      :maxlength="maxlength"
      :class="getClass"
      v-on="listeners"
      @input="onInput"
      @focus="onFocus"
      @click.stop
      @keyup.enter="onEnter"
    >

    <invalid-input
      v-if="invalid && invalidText !== ''"
      :center-text="centerText"
      class="invalid-input"
    >
      {{ invalidText }}
    </invalid-input>

    <div
      v-if="helpText"
      class="help-text"
    >
      {{ helpText }}
    </div>
  </div>
</template>

<script>
import { includes } from 'lodash-es'

const TYPES = [
  'text', 'password', 'email', 'number', 'url', 'float', 'color', 'date'
]

const VARIANTS = [
  'default', 'line', 'transparent'
]

export default {
  props: {
    bottomMargin: {
      type: Boolean,
      required: false,
      default: true
    },
    datatest: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'text',
      validator: type => includes(TYPES, type)
    },

    placeholder: {
      type: String,
      default: ''
    },

    variant: {
      type: String,
      default: 'default',
      validator: type => includes(VARIANTS, type)
    },

    disabled: {
      type: Boolean,
      default: false
    },

    invalid: {
      type: Boolean,
      default: false
    },

    warningMessage: {
      type: String,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },

    invalidText: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    rightLabel: {
      type: String,
      default: ''
    },

    centerText: {
      type: Boolean,
      default: false
    },

    forceFocus: {
      type: Boolean,
      default: false
    },

    step: {
      type: String,
      default: null
    },

    max: {
      type: String,
      default: null
    },

    maxlength: {
      type: Number,
      default: null
    },

    helpText: {
      type: String,
      default: null
    },

    mask: {
      type: String,
      default: null
    },

    inputId: {
      type: String,
      default: null
    },

    inputClasses: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      localValue: this.stringifyValue(this.value)
    }
  },

  computed: {
    classes: {
      get () {
        const classes = [this.variant]
        if (this.bottomMargin) classes.push('input')
        if (this.invalid) classes.push('invalid')
        if (this.centerText) classes.push('text-center')
        return classes
      }
    },

    inputElement: {
      get () {
        return this.$refs.inputElement
      }
    },

    listeners: {
      get () {
        const listeners = this.$listeners
        delete listeners.input
        delete listeners.change
        return listeners
      }
    },

    inputType: {
      get () {
        if (this.type === 'float') return 'number'
        return this.type
      }
    },

    inputStep: {
      get () {
        if (this.type === 'float' && !this.step) return '0.000000000000001'
        if (this.step) return this.step
        return null
      }
    },

    getClass: {
      get () {
        const classes = [...this.inputClasses]
        if (this.forceFocus) classes.push('force-focus')
        if (this.warningMessage) classes.push('warning')
        return classes
      }
    }
  },

  watch: {
    value (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.localValue) {
        this.localValue = this.stringifyValue(newVal)
      }
    }
  },

  mounted () {
    const value = this.stringifyValue(this.value)
    if (value !== this.localValue) {
      this.localValue = value
    }
  },

  methods: {
    maskInput (e) {
      const tokens = { '#': { pattern: /\d/ } }

      let patternIndex = 0
      let valIndex = 0
      let output = ''
      while (patternIndex < this.mask.length && valIndex < this.localValue.length) {
        const patternChar = this.mask[patternIndex]
        const valChar = this.localValue[valIndex]
        const token = tokens[patternChar]

        if (token) {
          if (token.pattern.test(valChar)) {
            output += valChar
            patternIndex++
          }
          valIndex++
        } else {
          output += patternChar
          patternIndex++
          if (valChar === patternChar) valIndex++ // user entered a char that is in the patttern: ' ', '(', ')' or '-'
        }
      }

      this.localValue = output
    },

    stringifyValue (value) {
      return (value === null || typeof value === 'undefined') ? '' : String(value)
    },

    resetCursorPosToSelection (e) {
      let cursorPos = e.target.selectionStart
      const valueAtCursor = e.target.value[cursorPos - 1]

      while (cursorPos < this.localValue.length && this.localValue.charAt(cursorPos - 1) !== valueAtCursor) cursorPos++

      if (e.target === document.activeElement) {
        this.$nextTick(() => {
          this.inputElement.setSelectionRange(cursorPos, cursorPos)
        })
      }
    },

    onInput (e) {
      if (this.mask) {
        this.maskInput()
        this.resetCursorPosToSelection(e)
      }

      if (this.localValue !== this.value) {
        this.$emit('input', this.localValue)
        this.$emit('change', e)
      }
    },

    onEnter () {
      this.$emit('enter', this.localValue)
    },

    onFocus (event) {
      this.$emit('focus')
      event.stopPropagation()
    },

    focus () {
      if (this.$refs.inputElement) this.$refs.inputElement.focus()
    },

    resetVal (val) {
      this.localValue = val
    }
  }
}
</script>

<style scoped>
input {
  font-family: var(--font-stack);
  width: 100%;
  border-color: var(--input-border-color);
  border-style: solid;
  color: var(--text-color);
  /* transition: all 0.3s ease; */
}

.force-focus {
  border-color: var(--input-focus-border-color);
}

.input {
  margin-bottom: 20px;
}

input:focus {
  border-color: var(--input-focus-border-color);
}

.default input {
  border-width: 1px;
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 7px 6px;
}

.default.invalid .invalid-input {
  margin-top: 6px;
  margin-bottom: 0px;
}

.line input {
  border-width: 0px 0px 1px 0px;
  border-color: var(--input-line-border-color);
  border-radius: 0px;
  font-size: 16px;
  padding: 5px 5px 5px 10px;
  margin-top: 40px;
  background-color: transparent;
}

.line input:focus {
  border-color: var(--input-focus-border-color);
}

.line {
  margin-bottom: 0px;
}

.transparent {
  margin-bottom: 0px;
}

.transparent input {
  background-color: transparent;
  border: none;
}

input[aria-invalid='true'],
input.invalid {
  border-color: var(--text-error-color);
}

.invalid-input {
  width: 100%;
}

input.warning {
  border: solid var(--text-error-color) 1px;
}

.label-with-warning {
  display: flex !important;
}

.warning-icon {
  margin-left: 5px;
  height: 11px;
}

.warning-icon :deep(.icon),
.warning-icon :deep(.icon:hover) {
  fill: var(--text-warning-color) !important;
}

.required-label:after {
  content: '*';
  color: var(--text-error-color);
  font-weight: bold;
  font-size: 11px;
  margin-right: 3px;
}

.help-text {
  width: 100%;
  margin-top: 6px;
  font-size: 12px;
  font-style: italic;
  color: var(--text-help-color);
}
</style>
