export default [
  {
    path: '/request-for-proposal/new',
    name: 'NewRequestForProposal',
    component: () => import('@/views/request-for-proposal/new/Index.vue'),
    meta: {
      navItem: 'My Workspace',
      headerText: 'Add New RFP',
      headerLogo: 'client',
      headerBackRoute: 'WorkspaceRequestForProposalList',
      subNav: 'none',
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'w-full'
    }
  },

  {
    path: '/request-for-proposal/:id/edit',
    name: 'EditRequestForProposal',
    component: () => import('@/views/request-for-proposal/edit/Index.vue'),
    meta: {
      navItem: 'My Workspace',
      headerText: '{{RFP}}',
      headerLogo: 'client',
      headerBackRoute: 'WorkspaceRequestForProposalList',
      subNav: 'none',
      contentExtraClass: 'no-content-padding',
      mainContentClass: 'w-full'
    }
  }
]
