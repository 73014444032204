export default [
  {
    path: '/test/form/:id',
    name: 'FormTest',
    component: () => import('@/views/test/Form.vue'),
    meta: {
      headerText: 'Form Test'
    }
  },
  {
    path: '/test/input-types',
    name: 'InputTypesTest',
    component: () => import('@/views/test/InputTypes.vue'),
    meta: {
      headerText: 'Input Types Test'
    }
  },
  {
    path: '/test/icons',
    name: 'IconsTest',
    component: () => import('@/views/test/Icons.vue'),
    meta: {
      headerText: 'Icons Test'
    }
  },
  {
    path: '/test/sparkle',
    name: 'SparkleTest',
    component: () => import('@/views/test/Sparkle.vue'),
    meta: {
      headerText: 'Sparkle Test'
    }
  }
]
